import React, { useEffect } from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./Components/Pages/OtherPages/LandingPage";
import SignIn from "./Components/Pages/OtherPages/SigninPage";
import SignUp from "./Components/Pages/OtherPages/SignupPage";
import Dashboard from "./Components/Pages/OtherPages/Dashboard";
import ExampleContentPage from "./Components/Pages/OtherPages/ExampleContentPage";
import Lessons from "./Components/Pages/Lessons/Lessons";
import LessonContent from "./Components/Pages/Lessons/LessonContent";
import Papers from "./Components/Pages/papers/AllPapers";
import SchedulePage from "./Components/Pages/OtherPages/SchedulePage";
import UserProfile from "./Components/Pages/UserProfile/UserProfile";
import EditProfile from "./Components/Pages/UserProfile/EditProfile";
import Announcements from "./Components/Pages/Announcements/AnnouncementsPage";

function App() {
  useEffect(() => {
    document.title = "IT GURA";
  }, []);
  return ( 
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<SignIn/>} />
        <Route path="/signup" element={<SignUp/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/lessons" element={<Lessons/>} />
        <Route path="/lessoncontent/:classid/:lessonNo" element={<LessonContent/>} />
        <Route path="/papers" element={<Papers/>} />
        <Route path="/schedule" element={<SchedulePage/>} />
        <Route path="/userprofile" element={<UserProfile/>} />
        <Route path="/editprofile" element={<EditProfile/>} />
        <Route path="/announcements" element={<Announcements/>} />
        <Route path="/ec" element={<ExampleContentPage/>} />
      </Routes>
    </Router>
  );
}
export default App;
