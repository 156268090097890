import React from "react";
import DashboardItem from "../Atoms/DashboardItem";
import { Link } from "react-router-dom";

export default function DashboardOptions() {
  return (
    <div>
      <div className="grid gap-4 md:grid-cols-3">
        <Link to={"/lessons"}>
          <div className="flex items-center ">
            <DashboardItem name="Lessons" icon="lesson" />
          </div>
        </Link>
        <div className="flex items-center ">
          <DashboardItem name="Syllabus" icon="syllabus" />
        </div>
        <div className="flex items-center ">
          <DashboardItem name="Teacher guide" icon="teacherguide" />
        </div>
        <div className="flex items-center ">
          <DashboardItem name="Papers" icon="paper" />
        </div>
        <div className="flex items-center ">
          <DashboardItem name="Assignments" icon="assignment" />
        </div>
        <div className="flex items-center ">
          <DashboardItem name="Quizzers" icon="quiz" />
        </div>
      </div>
    </div>
  );
}
