import React, { MouseEvent, useState } from "react";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";

interface RefreshModalProps {
  show: boolean;
  onClose: () => void;
}

const RefreshModal: React.FC<RefreshModalProps> = ({ show, onClose }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleTokenRefresh = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      setErrorMessage("Refresh token is missing. Please log in.");
      return;
    }

    try {
      const response = await axios.post(`${API_ENDPOINTS.REFRESH_TOKEN}${refreshToken}`, {
        
      });

      if (response.data.meta.code === 1) {
        const { authenticationToken, refreshToken: newRefreshToken } = response.data.data;

        // Store the new tokens in local storage
        localStorage.setItem("authenticationToken", authenticationToken);
        localStorage.setItem("refreshToken", newRefreshToken);
      
        setSuccessMessage("Token refreshed successfully.");
        setErrorMessage(null);
        window.location.reload();
        onClose(); // Close the modal after successful refresh
      } else {
        setErrorMessage("Failed to refresh token.");
        setSuccessMessage(null);
      }
    } catch (error) {
      setErrorMessage("Error occurred while refreshing token.");
      setSuccessMessage(null);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div className="fixed inset-0 bg-black opacity-40" onClick={handleOverlayClick}></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[80vw] md:h-[60vh] xl:h-[90vh] h-[60vh] shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <h2 className="text-lg font-semibold mb-4">Session Expired</h2>
              <p className="mb-4">Your session has expired. Please refresh your token.</p>
              
              {errorMessage && <div className="text-red-500">{errorMessage}</div>}
              {successMessage && <div className="text-green-500">{successMessage}</div>}

              <button
                onClick={handleTokenRefresh}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Refresh Token
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RefreshModal;
