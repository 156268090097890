import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import Dropzone from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";

interface EditDocumentModalProps {
    SubLessonId: number;
    show: boolean;
    onClose: () => void;
    id: number;
    type: "video" | "document";
    name: string;
    url?: string;
    uploadedDateTime: Date;
    selectedMonths: SelectedMonth[];
  }

interface SelectedMonth {  
  year: number;
  month: number;
}

const EditDocumentModal: React.FC<EditDocumentModalProps> = ({
    SubLessonId,
    show,
    onClose,
    id,
    type,
    name,
    url,
    uploadedDateTime,
    selectedMonths: initialSelectedMonths,
}) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [documentName, setDocumentName] = useState<string | undefined>(name);
  const [selectedMonths, setSelectedMonths] = useState<SelectedMonth[]>(
    initialSelectedMonths
  );

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    const [year, month] = selectedDate.split("-").map(Number);
    setSelectedMonths((prevSelectedMonths) => {
      const isMonthSelected = prevSelectedMonths.some(
        (selectedMonth) =>
          selectedMonth.year === year && selectedMonth.month === month
      );

      if (isMonthSelected) {
        return prevSelectedMonths.filter(
          (selectedMonth) =>
            selectedMonth.year !== year || selectedMonth.month !== month
        );
      } else {
        // If the month is not selected, add it
        return [...prevSelectedMonths, { year, month }];
      }
    });
  };
  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    console.log("Uploading files:", selectedFile);
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[95vw] md:h-[80vh]  xl:h-[95vh] h:[90vh] shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium ">Document Name</label>
                  </div>
                  <input
                    type="text"
                    id="documentname"
                    name="documentname"
                    placeholder={documentName}
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md "
                    required
                  />
                  <div className="flex items-start py-2">
                    <label className="mr-2 font-medium ">Access level</label>
                  </div>
                  <input
                    type="month"
                    id="months"
                    name="months"
                    onChange={handleMonthChange}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                  <div className="max-h-[15vh] overflow-y-auto  text-left">
                    <div className="flex flex-wrap mt-2">
                      {selectedMonths.map((selectedMonth, index) => (
                        <div key={index} className="w-1/3 p-1 md:w-1/5">
                          {selectedMonth.year}-{selectedMonth.month}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="w-full h-[10vh] border-dashed border-4 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer"
                      >
                        <input {...getInputProps()} />
                        <div className="items-center justify-center text-center">
                          <FaCloudUploadAlt
                            className="items-center justify-center mx-auto mb-2 text-4xl"
                            color="darkblue"
                          />
                          <p className="text-sm text-blue-900">
                            {selectedFile
                              ? selectedFile.name
                              : "  Drop file here, or click to select files"}
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="mx-auto ">
                  <CommonButton
                    size="medium"
                    model="blue"
                    text="Upload"
                    onClick={handleUpload}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditDocumentModal;
