import React, {useState } from "react";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Sidebar from "../../Ui/Templates/Sidebar";
import Header from "../../Ui/Templates/Header";
import CommonButton from "../../Ui/Atoms/Buttons";
import UpdateProfilePic from "../../Popups/UpdateProfilePic";

export default function EditProfile() {
  const [userProfile, setUserProfile] = useState({
    profilePicture: "Profile.png",
    firstName: "John",
    lastName: "Doe",
    index: "25168M",
    email: "john.doe@example.com",
    examinationYear: "2025",
    mobileno: "07xxxxxxxx",
    addressNo: "84/2",
    addressLine01: "Line1",
    addressLine02: "Line2", 
    city: "City",
  });

  const [showUpdateProfilePic, setShowUpdateProfilePic] = useState(false);

  const handleUpdateProfilePic = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    setShowUpdateProfilePic(show);
  };

  // const fetchUserDetails = async () => {
  //   try {
  //     const response = await fetch("/api/user");
  //     const data = await response.json();
  //     setUserProfile(data);
  //   } catch (error) {
  //     console.error("Error fetching user details:", error);
  //   }
  // };

  const updateUserDetails = async () => {
    try {
      const response = await fetch("/api/user/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userProfile),
      });

      if (response.ok) {
        console.log("User details updated successfully");
      } else {
        console.error("Failed to update user details");
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  return (
    <div className=" fle">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto">
            {/* Scrollable Content */}
            <div></div>
            <div className="min-h-screen p-4">
              <h1 className="pb-4 text-xl font-bold text-custom-darkblue">
                User Profile
              </h1>
              {/* Profile and name */}
              <div className="rounded-lg bg-custom-yellow">
                <div className="flex p-3 ">
                  <div className="flex items-center justify-center basis-auto">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${
                        userProfile.profilePicture
                          ? userProfile.profilePicture
                          : "noprofile.png"
                      }`}
                      alt="Profile"
                      className="w-[25vw] h-[25vw] sm:w-[15vw] sm:h-[15vw] xl:w-[10vw] xl:h-[10vw] rounded-full cursor-pointer hover:opacity-90"
                      onClick={() =>handleUpdateProfilePic(true)}
                    />
                  </div>
                  <div className="flex items-center justify-center ">
                    <div className="my-[9vw] sm:my-[4vw] xl:my-[2vw] mx-4 text-xl md:text-2xl font-semibold text-custom-darkblue pl-5">
                      {userProfile.firstName} {userProfile.lastName}
                      <div className="font-normal ">{userProfile.index}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Other details */}
              <div className="flex-1 pt-4 rounded-lg">
                <h1 className="text-lg font-semibold text-custom-darkblue ">
                  User details
                </h1>
                <form>
                  <div className="py-2 text-base">
                    <div className="font-medium ">First name</div>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder={userProfile.firstName}
                      value={userProfile.firstName}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          firstName: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Last name</div>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      placeholder={userProfile.lastName}
                      value={userProfile.lastName}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          lastName: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Email</div>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder={userProfile.email}
                      value={userProfile.email}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          email: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Mobile number</div>
                    <input
                      type="text"
                      id="mobileno"
                      name="mobileno"
                      placeholder={userProfile.mobileno}
                      value={userProfile.mobileno}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          mobileno: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Address</div>
                    <div className="py-2 text-sm ">Address No</div>
                    <input
                      type="text"
                      id="addressNo"
                      name="addressNo"
                      placeholder={userProfile.addressNo}
                      value={userProfile.addressNo}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          addressNo: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    <div className="py-2 text-sm ">Address Line 01</div>
                     <input
                      type="text"
                      id="addressLine01"
                      name="addressLine01"
                      placeholder={userProfile.addressLine01}
                      value={userProfile.addressLine01}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          addressLine01: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    <div className="py-2 text-sm ">Address Line 02</div>
                     <input
                      type="text"
                      id="addressLine02"
                      name="addressLine02"
                      placeholder={userProfile.addressLine02}
                      value={userProfile.addressLine02}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          addressLine02: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    <div className="py-2 text-sm ">Nearst city</div>
                     <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder={userProfile.city}
                      value={userProfile.city}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          addressLine01: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                  </div>
                </form>

                <div>
                  <CommonButton
                    size="medium"
                    model="blue"
                    text="Save"
                    onClick={updateUserDetails}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
      <UpdateProfilePic
        show={showUpdateProfilePic}
        onClose={() => handleUpdateProfilePic(false)}
      />
    </div>
  );
}
