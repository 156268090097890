import React, { useState } from "react";
import CommonButton from "../Atoms/Buttons";
import { FaPlus } from "react-icons/fa6";
import AddDocumentModal from "../../Popups/AddDocument";
import AddRecordingModal from "../../Popups/AddRecording";
import LearningMaterial from "../Atoms/LearningMaterial"; // Component

// Define the type separately
interface LearningMaterialData {
  id: number;
  type: "video" | "document";
  name: string;
  url?: string;
  uploadedDateTime: Date;
  subLessonId: number;
  selectedMonths: { year: number; month: number }[];
}

interface SubLessonProps {
  SubLessonId: number;
  parentLessonNo: number;
  classId: string;
  title: string;
}

// Use the LearningMaterialData type here
const learningMaterials: LearningMaterialData[] = [
  {
    id: 1,
    type: "video",
    name: "video name",
    url: "https://www.youtube.com/watch?v=yourvideoid",
    uploadedDateTime: new Date("2022-02-15T12:30:00"),
    subLessonId: 1,
    selectedMonths: [
      { year: 2022, month: 3 },
      { year: 2022, month: 4 },
    ],
  },
  {
    id: 2,
    type: "document",
    name: "Introduction_to_React",
    uploadedDateTime: new Date("2022-02-16T10:45:00"),
    subLessonId: 1,
    selectedMonths: [
      { year: 2022, month: 3 },
      { year: 2022, month: 4 },
    ],
  },
  {
    id: 3,
    type: "video",
    name: "video name 1",
    url: "https://www.youtube.com/watch?v=yourvideoid",
    uploadedDateTime: new Date("2020-02-10T14:20:00"),
    subLessonId: 1,
    selectedMonths: [
      { year: 2022, month: 3 },
      { year: 2022, month: 4 },
    ],
  },
  {
    id: 4,
    type: "document",
    name: "State_and_Lifecycle",
    uploadedDateTime: new Date("2022-02-20T16:05:00"),
    subLessonId: 2,
    selectedMonths: [
      { year: 2022, month: 3 },
      { year: 2022, month: 4 },
    ],
  },
];

const SubLesson: React.FC<SubLessonProps> = ({
  parentLessonNo,
  classId,
  title,
  SubLessonId,
}) => {
  const filteredMaterials = learningMaterials
    .filter((material) => material.subLessonId === SubLessonId)
    .sort((a, b) => a.uploadedDateTime.getTime() - b.uploadedDateTime.getTime());

  const [showAddDoc, setShowAddDoc] = useState(false);
  const [showAddRecording, setShowAddRecording] = useState(false);

  const handleAddDoc = (show: boolean | ((prevState: boolean) => boolean)) => {
    setShowAddDoc(show);
  };

  const handleAddRecording = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    setShowAddRecording(show);
  };

  return (
    <div>
      <div className="p-5 pb-10 mt-5 bg-blue-100 rounded-lg">
        <h1>
          <div className="flex text-xl font-semibold text-custom-darkblue">
            <div>{SubLessonId} - </div>
            <div>{title}</div>
          </div>
        </h1>
        <div>
          {filteredMaterials.map((material) => (
            <LearningMaterial key={material.id} material={material} />
          ))}
        </div>
      </div>
      <div className="flex ">
        <div>
          <CommonButton
            text={"Add document"}
            size="extrasmall"
            model="default"
            icon={FaPlus}
            onClick={() => handleAddDoc(true)}
          />
        </div>
        <div>
          <CommonButton
            text={"Add recording"}
            size="extrasmall"
            model="default"
            icon={FaPlus}
            onClick={() => handleAddRecording(true)}
          />
        </div>
      </div>
      <AddDocumentModal
        show={showAddDoc}
        onClose={() => handleAddDoc(false)}
        SubLessonId={SubLessonId}
      />
      <AddRecordingModal
        show={showAddRecording}
        onClose={() => handleAddRecording(false)}
        SubLessonId={SubLessonId}
      />
    </div>
  );
};

export default SubLesson;
