import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import Dropzone from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";

interface UpdateProfilePicProps {
  show: boolean;
  onClose: () => void;
}

const UpdateProfilePic: React.FC<UpdateProfilePicProps> = ({
  show,
  onClose,
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    console.log("Uploading files:", selectedFile);
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[60vw] md:h-[40vh]  xl:h-[70vh] h:[40vh] shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="w-full h-[200px] border-dashed border-4 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer"
                      >
                        <input {...getInputProps()} />
                        <div className="items-center justify-center text-center">
                          <FaCloudUploadAlt
                            className="items-center justify-center mx-auto mb-2 text-4xl"
                            color="darkblue"
                          />
                          <p className="text-sm text-blue-900">
                          {selectedFile? selectedFile.name:'  Drop file here, or click to select files'}
                          
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="mx-auto ">
                  <button>
                    <CommonButton
                      size="medium"
                      model="blue"
                      text="Upload"
                      onClick={handleUpload}
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateProfilePic;

