interface LessonData {
    lessonName: string;
    lessonNo: number;
    imagename: string;
  }
  
  // Dummy lessons data for reference
  export const MyLessonsData: LessonData[] = [
    { lessonName: "Concept of ICT", lessonNo: 1, imagename: "L1.jpg" },
    { lessonName: "Introduction to Computer", lessonNo: 2, imagename: "L2.jpg" },
    { lessonName: "Data Representation", lessonNo: 3, imagename: "L3.jpg" },
    { lessonName: "Fundamental of Digital Circuits", lessonNo: 4, imagename: "L4.jpg" },
    { lessonName: "Computer Operating System", lessonNo: 5, imagename: "L5.jpg" },
    { lessonName: "Data Communication and Networking", lessonNo: 6, imagename: "L6.jpg" },
    { lessonName: "Systems analysis and Design", lessonNo: 7, imagename: "L7.jpg" },
    { lessonName: "Database Management", lessonNo: 8, imagename: "L8.jpg" },
    { lessonName: "Programming", lessonNo: 9, imagename: "L9.jpg" },
    { lessonName: "Web Development", lessonNo: 10, imagename: "L10.jpg" },
    { lessonName: "Internet of Things", lessonNo: 11, imagename: "L11.jpg" },
    { lessonName: "ICT in Business", lessonNo: 12, imagename: "L12.jpg" },
    { lessonName: "New Trends and Future Directions of ICT", lessonNo: 13, imagename: "L13.jpg" },
    // Add more lesson data as needed
  ];