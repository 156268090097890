import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../Ui/Templates/NavBar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import CommonButton from "../../Ui/Atoms/Buttons";
import { FaRegUser } from "react-icons/fa6";
import { MdLockOutline } from "react-icons/md";
import ForgotPasswordModal1 from "../../Popups/FogotPasswordS1";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import axios from "axios";

const SignIn = () => {
  const [userEmailOrMobile, setUserEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [showFogotPasswordModal1, setShowFogotPasswordModal1] = useState(false);
  const [notification, setNotification] = useState({ type: "", message: "" }); // Notification state
  const navigate = useNavigate();

  const handleFogotPasswordModal1 = (show: boolean | ((prevState: boolean) => boolean)) => {
    setShowFogotPasswordModal1(show);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeepLoggedInChange = () => {
    setKeepLoggedIn(!keepLoggedIn);
  };

  const determineSignInMethod = () => {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmailOrMobile);
    const isMobileNumber = /^(\+?\d{2} ?\d{1,4} ?\d{1,4} ?\d{1,4})$/.test(userEmailOrMobile);

    if (isEmail) {
      signInWithEmail();
    } else if (isMobileNumber) {
      signInWithMobile();
    } else {
      setNotification({ type: "error", message: "Invalid email or mobile number" });
      clearNotification(); // Clear after a delay
    }
  };

  const signInWithEmail = async () => {
    try {
      const response = await axios.post(API_ENDPOINTS.LOGIN, {
        email: userEmailOrMobile,
        password: password,
      });
  
      // Check if the login was successful
      if (response.data.meta.code === 1) {
        localStorage.setItem("authenticationToken", response.data.data.authenticationToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
  
        setNotification({ type: "success", message: "Login successful!" });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000); // Redirect after a brief delay
      } else {
        setNotification({ type: "error", message: "Login failed:Bad credentials " });
        clearNotification(); // Clear after a delay
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Handle Axios error
        if (error.response && error.response.data.error) {
          setNotification({ type: "error", message: "Login failed: " + error.response.data.error.detail });
        } else {
          setNotification({ type: "error", message: "An unexpected error occurred. Please try again." });
        }
      } else {
        // Handle non-Axios errors
        setNotification({ type: "error", message: "An unexpected error occurred." });
      }
      clearNotification(); // Clear after a delay
    }
  };
  const signInWithMobile = () => {
    console.log("Signing in with mobile");
    window.location.href = "/dashboard";
  };

  const handleSignIn = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    determineSignInMethod();
  };

  // Function to clear notification after 3 seconds
  const clearNotification = () => {
    setTimeout(() => {
      setNotification({ type: "", message: "" });
    }, 3000); // Adjust the delay as needed
  };

  return (
    <div className="">
      <div className="w-full">
        <NavBar />
      </div>

      {/* Notification Section */}
      {notification.message && (
        <div
          className={`${
            notification.type === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
          } border-l-4 p-4 mb-4`}
          role="alert"
        >
          <p className="font-bold">{notification.type === "success" ? "Success" : "Error"}</p>
          <p>{notification.message}</p>
        </div>
      )}

      <div className="flex flex-col md:flex-row min-h-[65vh]">
        <div className="basis-1/3 ">
          <img
            src={`${process.env.PUBLIC_URL}/images/signinimg.png`}
            alt="Illustration of a login page"
            className="z-10 mx-auto max-w-[0vw] md:max-w-full"
          />
        </div>
        <div className="basis-2/3 ">
          <img
            src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
            alt="Logo"
            className=" mx-auto max-w-[50vw] md:max-w-sm"
          />
          <h1 className="text-lg font-bold text-center text-custom-lightblue md:text-3xl">
            Start your journey with us!
          </h1>

          <div className="container px-12 pb-10 mx-auto mt-8 md:px-36">
            <form onSubmit={handleSignIn}>
              <div className="mb-4">
                <label
                  htmlFor="usernameOrEmailOrMobile"
                  className="block text-lg font-medium text-gray-900"
                >
                  Email or Mobile Number
                </label>
                <div className="relative">
                  <FaRegUser className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-4" />
                  <input
                    type="text"
                    id="userEmailOrMobile"
                    name="userEmailOrMobile"
                    placeholder="Type your email or mobile number"
                    value={userEmailOrMobile}
                    onChange={(e) => setUserEmailOrMobile(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-lg font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="relative">
                  <MdLockOutline className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-5" />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Type your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute text-gray-500 transform -translate-y-1/2 right-2 top-1/2"
                  />
                </div>
              </div>

              <div className="flex flex-row ">
                <div className="mt-4 basis-1/2">
                  <label htmlFor="keepLoggedIn" className="flex items-center">
                    <input
                      type="checkbox"
                      id="keepLoggedIn"
                      checked={keepLoggedIn}
                      onChange={handleKeepLoggedInChange}
                      className="mr-2"
                    />
                    Keep me logged in
                  </label>
                </div>
                <div className="mt-4 basis-1/2">
                  <Link
                    to="#"
                    className="text-blue-700 underline"
                    onClick={() => handleFogotPasswordModal1(true)}
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <button type="submit">
                <CommonButton size="large" model="blue" text="Sign in" />
              </button>
            </form>
            <p className="mt-4">
              Don’t have an account?{" "}
              <Link to="/signup" className="text-blue-700">
                Create
              </Link>
            </p>
          </div>
        </div>
      </div>

      {/* FOOTER SECTION */}
      <div className="">
        <FooterWithSitemap />
      </div>

      {/* FOGOT PASSWORD MODAL */}
      <ForgotPasswordModal1
        show={showFogotPasswordModal1}
        onClose={() => handleFogotPasswordModal1(false)}
      />
    </div>
  );
};

export default SignIn;
