import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router for navigation
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import { Calendar } from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import DashboardOptions from "../../Ui/Organisms/DashboardOptions";
import 'react-calendar/dist/Calendar.css';
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";

const Dashboard = () => {
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // React Router hook for navigation

  const handleChange = (
    newDate: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (newDate instanceof Date) {
      setDate(newDate);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("authenticationToken");

    // Simulate token validation (this could be an API call)
    const validateToken = async () => {
      if (!token) {
        // Token is missing, redirect to login
        navigate("/signin");
      } else {
        try {
          // Add your token validation logic here (e.g., decoding token or checking expiration)
          const isValid = await fakeTokenValidation(token); // Replace with actual validation function
          if (!isValid) {
            navigate("/signin"); // Redirect to login if token is invalid
          } else {
            setLoading(false); // If valid, stop loading and show dashboard
          }
        } catch (error) {
          navigate("/signin"); // Redirect to login on error
        }
      }
    };

    validateToken();
  }, [navigate]);

  // Placeholder token validation function (replace with real logic)
  const fakeTokenValidation = async (token: string) => {
    try {
      const response = await axios.get(API_ENDPOINTS.VALIDATE_TOKEN+`token=${token}`);
      return response.data.data;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while validating the token
  }

  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="overflow-y-auto w-full pt-[5vh] md:pt-0 bg-blue-50">
            <div className="">
              <img
                src={`${process.env.PUBLIC_URL}/images/DashboardPic.png`}
                alt="DashboardPicture"
                className="w-full"
              />
              <div className="pl-2 lg:flex">
                <div className="mr-5 basis-auto lg:basis-3/4">
                  <h1 className="p-2 text-xl font-bold text-custom-darkblue">
                    Dashboard
                  </h1>
                  <DashboardOptions />
                </div>
                <div className="basis-auto lg:basis-1/3 min-w-[20vw] items-center justify-center">
                  <div className="">
                    <h1 className="pt-2 pl-2 text-xl font-semibold text-custom-darkblue">
                      Calendar
                    </h1>
                    <div className="my-5">
                      <Calendar onChange={handleChange} value={date} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
