import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import Dropzone from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";

interface AddPaperModalProps {
  show: boolean;
  onClose: () => void;
}

const AddPaperModal: React.FC<AddPaperModalProps> = ({ show, onClose }) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [paperType, setPaperType] = useState<string | undefined>();

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    console.log("Uploading files:", selectedFile);
    console.log("Selected paper type:", paperType);

    if (paperType === "PastPaper") {
      console.log("Uploading PastPaper:", selectedFile);
    } else if (paperType === "ModelPaper") {
      console.log("Uploading ModelPaper:", selectedFile);
    } else {
      console.log("No paper type selected");
      // Handle the case where no paper type is selected
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[80vw] md:h-[60vh]  xl:h-[90vh] h:[60vh] shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                {/* Radio buttons for selecting paper type */}
                <div className="flex items-center p-4 my-2 border-2 rounded-lg">
                  <label className="inline-flex items-center mr-4 ">
                    <input
                      type="radio"
                      value="PastPaper"
                      checked={paperType === "PastPaper"}
                      onChange={() => setPaperType("PastPaper")}
                      className="text-blue-500 form-radio"
                    />
                    <span className="ml-2 text-blue-900">Past Paper</span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="ModelPaper"
                      checked={paperType === "ModelPaper"}
                      onChange={() => setPaperType("ModelPaper")}
                      className="text-blue-500 form-radio"
                    />
                    <span className="ml-2 text-blue-900">Model Paper</span>
                  </label>
                </div>
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium ">Name</label>
                  </div>
                  <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder={name}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md "
                      required
                    />
                </div>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="w-full h-[200px] border-dashed border-4 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer"
                      >
                        <input {...getInputProps()} />
                        <div className="items-center justify-center text-center">
                          <FaCloudUploadAlt
                            className="items-center justify-center mx-auto mb-2 text-4xl"
                            color="darkblue"
                          />
                          <p className="text-sm text-blue-900">
                            {selectedFile
                              ? selectedFile.name
                              : "  Drop file here, or click to select files"}
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="mx-auto ">
                  <CommonButton
                    size="medium"
                    model="blue"
                    text="Upload"
                    onClick={handleUpload}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPaperModal;
