import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";

interface AddSubLessionModalProps {
  show: boolean;
  onClose: () => void;
}

const AddSubLessionModal: React.FC<AddSubLessionModalProps> = ({
  show,
  onClose,
}) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [title, setTitle] = useState<string>("");

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submitted");
    try {
      // const eventData = {
      //   title,
      //   startDateTime,
      //   endDateTime,
      //   isRecurrent,
      //   selectedClass,
      // };

      // Call your backend function here
      // await yourBackendFunction(eventData);

      // Close the modal after a successful submission
      onClose();
    } catch (error) {
      console.error("Error submitting event:", error);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[60vw] md:h-[30vh]  xl:h-[70vh] h:[30vh] shadow-lg rounded-3xl">
            <div className="">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className="scale-50 "
                />
              </div>
              <h2 className="items-center mx-auto text-lg font-semibold text-center text-blue-700">
                Add Sub Lesson
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={handleTitleChange}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div className="items-center mx-auto text-center">
                  <div className="">
                    <button type="submit">
                      <CommonButton size="small" model="blue" text="Set" />
                    </button>
                  </div>
                  <div className="">
                    <button>
                      <CommonButton size="small" text="Cancel" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddSubLessionModal;
