// src/store/rootReducer.ts

import { combineReducers } from 'redux';
import sidebarReducer, { SidebarState } from './sidebar';

interface RootState {
  sidebar: SidebarState;
  // Add other state slices here
}

const rootReducer = combineReducers<RootState>({
  sidebar: sidebarReducer,
  // Add other reducers here
} as any); // Add 'as any' to avoid the type error

export default rootReducer;
