import React from "react";
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";

const ExampleContentPage = () => {
  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto">
            {/* Scrollable Content */}
            <div className="min-h-screen p-4"></div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExampleContentPage;
