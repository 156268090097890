// Import the necessary icons
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa6";

export function FooterWithSitemap() {
  const SITEMAP = [
    {
      title: "IT GURA",
      links: [
        { name: "About Us", url: "/about-us" },
        { name: "Resources", url: "/careers" },
        { name: "Our store", url: "/our-team" },
        { name: "Contact us", url: "/projects" },
      ],
    },
  ];


  return (
    <footer className="">
      <div className="text-white bg-custom-darkblue">
      <div className="container flex flex-wrap justify-between gap-8 md:flex-row p-5 md:px-[10vw]">
        {SITEMAP.map(({ title, links }, key) => (
          <div key={key} className="mb-8">
            <h2 className="mb-4 text-lg font-semibold">{title}</h2>
            <ul className="space-y-2">
              {links.map(({ name, url }, index) => (
                <li key={index}>
                  <a href={url} className="hover:text-blue-300">
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div>
          <div className="mb-4 text-lg font-semibold"> Contact us</div>
          <div className="pb-2 cursor-pointer hover:text-blue-300">
            itguratest@gmail.com
          </div>
          <div className="py-2 cursor-pointer hover:text-blue-300">
            0771234567
          </div>
          <div className="py-2 cursor-pointer hover:text-blue-300">
            No 07, Colombo, Sri Lanka
          </div>
        </div>
        <div>
          <div className="mb-4 text-lg font-semibold"> Subscribe</div>
          <div className="pb-2 cursor-pointer hover:text-blue-300 md:w-[20vw]">
            Sign Up for our newsletter to know more about latest vacancies and
            blog updates
          </div>
          <div className="py-2 cursor-pointer hover:text-blue-300">Email</div>
          <div className="py-2 cursor-pointer hover:text-blue-300">
            <input
              type="text"
              className="p-1 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 p-5 text-blue-300 sm:justify-center">
        <a href="/">
          <div className="p-1 bg-white rounded-lg">
            <FaFacebookF color="#073863" size={30} />
          </div>
        </a>
        <a href="/">
          <div className="p-1 bg-white rounded-lg">
            <FaLinkedinIn color="#073863" size={30} />
          </div>
        </a>
        <a href="/">
          <div className="p-1 bg-white rounded-lg">
            <FaInstagram color="#073863" size={30} />
          </div>
        </a>
        <a href="/">
          <div className="p-1 bg-white rounded-lg">
            <FaTwitter color="#073863" size={30} />
          </div>
        </a>
        
      </div>
      
      </div>
      
      <div className="xl:h-[15vh] h-[5vh] bg-custom-yellow"></div>
    </footer>
  );
}
