import React, { useEffect, useState } from "react";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { TfiAnnouncement } from "react-icons/tfi";
import { MdOutlineForum } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import CommonButton from "../../Ui/Atoms/Buttons";
import AddSubLessionModal from "../../Popups/AddSubLession";
import SubLessonList from "../../Ui/Organisms/SubLessonList";
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";

interface ClassDetails {
  id: string;
  year: number;
  class_name: string;
  content_access_type: string;
}

export default function LessonContent() {
  const [showAddSubLesonModal, setShowAddSubLessonModal] = useState(false);
  const [classDetails, setClassDetails] = useState<ClassDetails | null>(null);
  const { classid } = useParams<{ classid: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const { lessonNo } = useParams<{ lessonNo: string }>();
  const lessonNumber: number = lessonNo ? parseInt(lessonNo, 10) : 0;

  // Fetch class details when the component mounts or classid changes
  useEffect(() => {
    const fetchClassDetails = async () => {
      setLoading(true);
      const token = localStorage.getItem("authenticationToken"); // Get token from localStorage

      try {
        const response = await axios.get(
          `${API_ENDPOINTS.GET_CLASS_By_ID}/${classid}`, // API to get class details by ID
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add authorization header
            },
          }
        );
        setClassDetails(response.data.data); // Set class details from API response
      } catch (error) {
        console.error("Error fetching class details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (classid) {
      fetchClassDetails();
    }
  }, [classid]);

  const handleNewSubLessonModal = () => {
    setShowAddSubLessonModal(true);
  };

  const handleSubLessonClose = () => {
    setShowAddSubLessonModal(false);
  };

  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto">
            {/* Scrollable Content */}
            <div className="min-h-screen p-4 bg-blue-50">
              {loading ? (
                <div>Loading...</div>
              ) : classDetails ? (
                <>
                  <h1 className="mb-4 text-2xl font-semibold sm:text-3xl">
               {classDetails.year} {classDetails.class_name}
                  </h1>
                  <h1 className="mb-4 text-lg sm:text-xl">
                    Lesson {lessonNo} - Basic Concepts of Information and Communication Technology
                  </h1>

                  <Link to={"/announcements"}>
                    <div className="flex w-full py-1 text-blue-600 cursor-pointer basis-auto">
                      <TfiAnnouncement className="justify-center w-4 h-4 m-1" />
                      <span className="underline">Announcement</span>
                    </div>
                  </Link>

                  <div className="flex w-full py-1 text-blue-600 cursor-pointer basis-auto">
                    <MdOutlineForum className="justify-center w-4 h-4 m-1" />
                    <span className="underline">Student forum</span>
                  </div>

                  <div className="">
                    <CommonButton
                      text={"Add Sub lesson"}
                      size="extrasmall"
                      model="blue"
                      icon={FaPlus}
                      onClick={handleNewSubLessonModal}
                    />

                    <div className="flex-col">
                      <SubLessonList
                        parentLessonNo={lessonNumber}
                        classId={classid??''}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div>No class details available</div>
              )}
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
      <AddSubLessionModal
        show={showAddSubLesonModal}
        onClose={handleSubLessonClose}
      />
    </div>
  );
}
