import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";

interface AddClassModalProps {
  show: boolean;
  onClose: () => void;
}

const AddClassModal: React.FC<AddClassModalProps> = ({ show, onClose }) => {
  const [name, setName] = useState<string>("");
  const [year, setYear] = useState<number>(2023);
  const [fees, setFees] = useState<number>(0);
  const [contentAccessType, setContentAccessType] = useState<string>("PUBLIC");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("authenticationToken");
    if (!token) {
      setErrorMessage("Authentication token is missing. Please log in.");
      return;
    }

    const requestBody = {
      data: {
        class_name: name,
        year: year,
        fees: fees,
        content_access_type: contentAccessType,
      },
    };

    try {
      const response = await fetch("http://localhost:8092/api/v1/resource-management/class/create", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();
      if (response.ok) {
        setSuccessMessage(result.data);
        setErrorMessage(null); // Clear any previous error
        // Reset the form after successful submission
        setName("");
        setYear(2023);
        setFees(0);
        setContentAccessType("PUBLIC");
      } else {
        setErrorMessage(result.error || "Failed to create class");
      }
    } catch (error) {
      setErrorMessage("An error occurred while creating the class.");
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div className="fixed inset-0 bg-black opacity-40" onClick={handleOverlayClick}></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[80vw] md:h-[60vh]  xl:h-[90vh] h:[60vh] shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                {/* Name Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Class Name</label>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                </div>

                {/* Year Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Year</label>
                  </div>
                  <input
                    type="number"
                    id="year"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                </div>

                {/* Fees Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Fees</label>
                  </div>
                  <input
                    type="number"
                    id="fees"
                    name="fees"
                    value={fees}
                    onChange={(e) => setFees(parseFloat(e.target.value))}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                </div>

                {/* Content Access Type */}
                <div className="py-2 text-base">
                  <label className="mr-2 font-medium">Content Access Type</label>
                  <select
                    id="contentAccessType"
                    name="contentAccessType"
                    value={contentAccessType}
                    onChange={(e) => setContentAccessType(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md shadow-md"
                  >
                    <option value="PUBLIC">Public</option>
                    <option value="REGISTERED">Registered</option>
                    <option value="PAID">Paid</option>
                  </select>
                </div>

                {/* Upload Button */}
                <div className="mx-auto py-4">
                  <CommonButton size="medium" model="blue" text="Create Class" onClick={handleSubmit} />
                </div>
                
                {/* Display Success or Error Messages */}
                {successMessage && <div className="text-green-600">{successMessage}</div>}
                {errorMessage && <div className="text-red-600">{errorMessage}</div>}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddClassModal;
