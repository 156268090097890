import { useState } from "react";
import CommonButton from "../Atoms/Buttons";

export function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      const response = await fetch("https://your-backend-api.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle successful submission
        console.log("Form submitted successfully");
      } else {
        // Handle errors
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="text-xs text-slate-100 md:text-sm lg:text-lg">
      <h1 className="text-3xl font-bold text-center text-custom-darkblue md:text-5xl">
        Contact us
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div className="mb-7">
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 text-gray-800 bg-transparent border-b border-gray-600 focus:outline-none focus:border-blue-500"
              placeholder="First Name"
            />
          </div>

          <div className="mb-7">
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 text-gray-800 bg-transparent border-b border-gray-600 focus:outline-none focus:border-blue-500"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className="mb-7">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-800 bg-transparent border-b border-gray-600 focus:outline-none focus:border-blue-500"
            placeholder="Email Address"
          />
        </div>

        <div className="mb-7">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-800 bg-transparent border-b border-gray-600 focus:outline-none focus:border-blue-500"
            placeholder="Your Message"
          ></textarea>
        </div>

        <div className="text-center">
          <button type="submit" className="your-button-styles">
          <CommonButton
                    size="large"
                    model="blue"
                    text="Send"
                  />
          </button>
        </div>
      </form>
    </div>
  );
}
