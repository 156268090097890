import React from "react";
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";

export interface AnnouncementProps {
  id: string;
  title: string;
  description: string;
  date: Date;
  onDelete: (id: string) => void; // Add a callback for delete action
}

const Announcement: React.FC<AnnouncementProps> = ({
  id,
  title,
  description,
  date,
  onDelete,
}) => {

  // Delete handler with Bearer token
  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this announcement?");
  
    if (confirmDelete) {
      try {
        const token = localStorage.getItem("authenticationToken"); // Retrieve token from localStorage
  
        if (!token) {
          alert("Authentication token is missing. Please log in.");
          return;
        }
  
        // Make DELETE request with Bearer token in the Authorization header
        const response = await axios.delete(`${API_ENDPOINTS.DELETE_ANNOUNCEMENT}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Pass Bearer token
          },
        });
  
        console.log('Response Status:', response.status); // Check response status
        console.log('Response Data:', response.data); // Log full response data
  
        // Check if deletion was successful based on the response
        if (response.data && response.data.meta && response.data.meta.code === 1) {
          // Trigger the onDelete callback to remove the announcement from the list
          onDelete(id);
          alert(response.data.data); // Show the success message from the response
        } else {
          // Log if the server responds with an unsuccessful message
          console.error("Failed to delete announcement. Server response:", response.data);
          alert("Failed to delete the announcement. Please try again.");
        }
  
      } catch (error) {
        // Handle and log actual errors caught in the catch block
        console.error("Error deleting announcement:", error);
        alert("An error occurred while deleting the announcement.");
      }
    }
  };
  
  return (
    <div className="announcement flex p-4 mb-4 bg-white rounded-lg shadow-md hover:bg-gray-100 transition duration-300">
      {/* Company Logo */}
      <div className="mr-4 flex-shrink-0">
        <img
          src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
          alt="Company Logo"
          className="w-20"
        />
      </div>

      {/* Announcement Content */}
      <div className="flex-1">
        {/* Title and Date */}
        <div className="flex justify-between items-center">
          <h2 className="text-sm font-semibold text-gray-800">{title}</h2>
          <span className="text-xs text-gray-500">{date.toLocaleString()}</span>
        </div>

        {/* Description */}
        <p className="mt-1 text-gray-700 text-sm">{description}</p>
      </div>

      {/* Delete Button */}
      <div className="ml-4 flex items-center">
        <button
          onClick={handleDelete}
          className="text-red-500 hover:text-red-700 text-sm transition duration-200"
        >
          Delete
        </button>
      </div>
    </div> 
  );
};

export default Announcement;
