// Announcements.tsx
import React, { useState, useEffect } from "react";
import Announcement, { AnnouncementProps } from "../../Ui/Organisms/Announcement";
import Sidebar from "../../Ui/Templates/Sidebar";
import Header from "../../Ui/Templates/Header";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import ReactPaginate from "react-paginate";
import CommonButton from "../../Ui/Atoms/Buttons";
import AddAnnuncementModal from "../../Popups/AddAnnuncement";
import { API_ENDPOINTS } from "../../../services/api_endpoints";

interface ClassData {
  id: string;
  year: number;
  class_name: string;
  content_access_type: string;
}

export default function Announcements() {
  const [showAddAnnouncementModal, setShowAddAnnouncementModal] = useState(false);
  const [classDataArray, setClassDataArray] = useState<ClassData[]>([]);
  const [selectedClass, setSelectedClass] = useState<string>(""); // Track selected class ID
  const [announcements, setAnnouncements] = useState<AnnouncementProps[]>([]);
  const [loadingClasses, setLoadingClasses] = useState<boolean>(false);
  const [loadingAnnouncements, setLoadingAnnouncements] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const announcementsPerPage = 7;
  const totalPages = Math.ceil(announcements.length / announcementsPerPage);

  const startIndex = currentPage * announcementsPerPage;
  const endIndex = startIndex + announcementsPerPage;
  const currentAnnouncements = announcements.slice(startIndex, endIndex);

  // Fetch the list of available classes when the component mounts
  useEffect(() => {
    const fetchClasses = async () => {
      setLoadingClasses(true);
      try {
        const token = localStorage.getItem("authenticationToken");
        if (!token) {
          setError("Authentication token is missing. Please log in.");
          return;
        }

        const response = await fetch(API_ENDPOINTS.GET_CLASSES, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          setClassDataArray(result.data); // Assuming `result.data` is an array of classes
        } else {
          setError(result.error || "Failed to fetch classes.");
        }
      } catch (err) {
        setError("An error occurred while fetching classes.");
      } finally {
        setLoadingClasses(false);
      }
    };

    fetchClasses();
  }, []);

  // Fetch announcements for the selected class
  const fetchAnnouncements = async (classId: string) => {
    setLoadingAnnouncements(true);
    setError(null);
    try {
      const token = localStorage.getItem("authenticationToken");
      if (!token) {
        setError("Authentication token is missing. Please log in.");
        return;
      }

      const response = await fetch(`${API_ENDPOINTS.GET_ANNOUNCEMENTS}/${classId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (response.ok) {
        // Map the response data to the expected structure
        const announcementsData: AnnouncementProps[] = result.data.map((announcement: any) => ({
          id: announcement.announcement_id,
          title: announcement.announcement_title,
          description: announcement.announcement_description,
          date: new Date(announcement.announcement_on),
        }));

        setAnnouncements(announcementsData); // Set the formatted announcements
        setCurrentPage(0); // Reset to first page on new fetch
      } else {
        setError(result.error || "Failed to fetch announcements.");
      }
    } catch (err) {
      setError("An error occurred while fetching announcements.");
    } finally {
      setLoadingAnnouncements(false);
    }
  };

  // Handle class selection from the dropdown
  const handleClassSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const classId = e.target.value;
    setSelectedClass(classId);
    if (classId) {
      fetchAnnouncements(classId);
    } else {
      setAnnouncements([]); // Clear announcements if no class is selected
    }
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleAddAnnouncementModal = () => {
    if (!selectedClass) {
      alert("Please select a class before adding an announcement.");
      return;
    }
    setShowAddAnnouncementModal(true);
  };

  const handleAddAnnouncementClose = () => {
    setShowAddAnnouncementModal(false);
    if (selectedClass) {
      fetchAnnouncements(selectedClass); // Refresh announcements after adding
    }
  };
  const handleDelete = (id: string) => {
    // Logic to remove the announcement from state
    setAnnouncements(prevAnnouncements => prevAnnouncements.filter(announcement => announcement.id !== id));
  };


  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar /> {/* Assuming Sidebar is for navigation only */}
          <div className="w-full overflow-y-auto">
            <div className="flex flex-col min-h-screen p-4">
              <div className="flex-1 border-2 rounded-xl p-4">
                <h1 className="text-2xl font-bold border-b-2 text-custom-darkblue mb-4">
                  Announcements
                </h1>

                {/* Class Selection Dropdown */}
                <div className="mb-4">
                  <label
                    htmlFor="classSelect"
                    className="block text-sm font-semibold text-gray-600 mb-2"
                  >
                    Select Class
                  </label>
                  {loadingClasses ? (
                    <p>Loading classes...</p>
                  ) : (
                    <select
                      id="classSelect"
                      value={selectedClass}
                      onChange={handleClassSelect}
                      className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    >
                      <option value="">-- Select a Class --</option>
                      {classDataArray.map((classItem) => (
                        <option key={classItem.id} value={classItem.id}>
                          {classItem.year} {classItem.class_name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2 mb-4">
                  <CommonButton
                    size="extrasmall"
                    model="blue"
                    text="Add new"
                    onClick={handleAddAnnouncementModal}
                  />
                  
                </div>

                {/* Display Announcements */}
                <div className="flex-1 overflow-y-auto">
                  {loadingAnnouncements ? (
                    <p>Loading announcements...</p>
                  ) : error ? (
                    <p className="text-red-500">{error}</p>
                  ) : currentAnnouncements.length > 0 ? (
                    currentAnnouncements.map((announcement) => (
                      <Announcement
                        key={announcement.id}
                        id={announcement.id}
                        title={announcement.title}
                        description={announcement.description}
                        date={announcement.date} 
                        onDelete={handleDelete}                     />
                    ))
                  ) : selectedClass ? (
                    <p>No announcements to display for the selected class.</p>
                  ) : (
                    <p>Please select a class to view announcements.</p>
                  )}
                </div>
              </div>

              {/* Pagination */}
              {announcements.length > announcementsPerPage && (
                <ReactPaginate
                  pageCount={totalPages}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName="flex justify-center mt-6"
                  pageClassName="mx-2"
                  activeClassName="border-b-4 border-custom-yellow rounded"
                  pageLinkClassName="text-white bg-custom-darkblue rounded px-4 py-3"
                  previousClassName="px-4 py-2 mr-2 text-custom-darkblue rounded bg-custom-yellow"
                  nextClassName="px-4 py-2 ml-2 text-black rounded bg-custom-yellow"
                  previousLabel="Previous"
                  nextLabel="Next"
                />
              )}
            </div>
            <div>
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>

      {/* Add Announcement Modal */}
      <AddAnnuncementModal
        show={showAddAnnouncementModal}
        onClose={handleAddAnnouncementClose}
        selectedClass={selectedClass} // Pass selected class to the modal
      />
    </div>
  );
}
