import React, { useEffect, useState } from "react";
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import CommonButton from "../../Ui/Atoms/Buttons";
import { Link } from "react-router-dom";

export default function UserProfile() {
  const [userProfile, setUserProfile] = useState({
    profilePicture: "Profile.png",
    firstName: "John",
    lastName: "Doe",
    index: "25168M",
    email: "john.doe@example.com",
    examinationYear: "2025",
    mobileno: "07xxxxxxxx",
    addressNo: "84/2",
    addressLine01: "Line1",
    addressLine02: "Line2", 
    city: "City",
   });

  useEffect(() => {
    fetch("backend-api-url")
      .then((response) => response.json())
      .then((data) => setUserProfile(data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  return (
    <div className=" fle">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto">
            {/* Scrollable Content */}
            <div></div>
            <div className="min-h-screen p-4">
              <h1 className="pb-4 text-xl font-bold text-custom-darkblue">
                User Profile
              </h1>

              {/* Profile and name */}
              <div className="rounded-lg bg-custom-yellow">
                <div className="flex p-3 ">
                  <div className="flex items-center justify-center basis-auto">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${
                        userProfile.profilePicture
                          ? userProfile.profilePicture
                          : "noprofile.png"
                      }`}
                      alt="Profile"
                      className="w-[25vw] h-[25vw] sm:w-[15vw] sm:h-[15vw] xl:w-[10vw] xl:h-[10vw] rounded-full "
                    />
                  </div>
                  <div className="flex items-center justify-center ">
                    <div className="my-[9vw] sm:my-[4vw] xl:my-[2vw] mx-4 text-xl md:text-2xl font-semibold text-custom-darkblue pl-5">
                      {userProfile.firstName} {userProfile.lastName}
                      <div className="font-normal ">{userProfile.index}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Other details */}
              <div className="flex-1 pt-4 rounded-lg">
                <h1 className="text-lg font-semibold text-custom-darkblue ">
                  User details
                </h1>

                <div className="py-2 text-base">
                  <div className="font-medium ">Email</div>
                  <div className="w-full border-2 rounded-lg border-slate-400">
                    <div className="m-3 ">{userProfile.email}</div>
                  </div>
                </div>
                <div className="py-2 text-base">
                  <div className="font-medium ">Mobile number</div>
                  <div className="w-full border-2 rounded-lg border-slate-400">
                    <div className="m-3 ">{userProfile.mobileno}</div>
                  </div>
                </div>
                <div className="py-2 text-base">
                  <div className="font-medium ">Examination Year</div>
                  <div className="w-full border-2 rounded-lg border-slate-400">
                    <div className="m-3 ">{userProfile.examinationYear}</div>
                  </div>
                </div>
                <div className="py-2 text-base">
                  <div className="font-medium ">Address</div>
                  <div className="w-full border-2 rounded-lg border-slate-400">
                    <div className="m-3 ">{userProfile.addressNo}</div>
                    <div className="m-3 ">{userProfile.addressLine01}</div>
                    <div className="m-3 ">{userProfile.addressLine02}</div>
                    <div className="m-3 ">{userProfile.city}</div>
                  </div>
                </div>
                <div>
                  <Link to="/editprofile">
                    <CommonButton
                      size="medium"
                      model="blue"
                      text="Edit details"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
