import React from "react";
import SubLesson from "./SubLesson";

interface SubLessonListProps {
  parentLessonNo: number;
  classId: String;
}

// Dummy data array
const sublessons = [
  { SubLessonId: 1, parentLessonNo: 1, classId: '57bd5f1b-6efe-4327-957f-1f02c630f66b', title: "Introduction to React" },
  { SubLessonId: 2, parentLessonNo: 1, classId: '', title: "Components and Props" },
  { SubLessonId: 3, parentLessonNo: 2, classId: '', title: "State and Lifecycle" },
  { SubLessonId: 4, parentLessonNo: 2, classId: '', title: "Handling Events" },
];

const SubLessonList: React.FC<SubLessonListProps> = ({ parentLessonNo, classId }) => {
  const sublessonsForParent = sublessons.filter(
    (sublesson) => sublesson.parentLessonNo === parentLessonNo && sublesson.classId === classId
  );

  return (
    <div className="flex-col">
      {sublessonsForParent.map((sublesson) => (
        <SubLesson key={sublesson.SubLessonId} {...sublesson} />
      ))}
    </div>
  );
};

export default SubLessonList;
