import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Sidebar from "../../Ui/Templates/Sidebar";
import Header from "../../Ui/Templates/Header";
import AddEventModal from "../../Popups/AddEvent";
import CommonButton from "../../Ui/Atoms/Buttons";

export default function SchedulePage() {
  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto md:pt-0">
            {/* Scrollable Content */}
            <Scheduler />
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export function Scheduler() {
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const sampleEvents = [
    {
      title: "Event 1",
      start: "2024-02-16T09:10:00",
      end: "2024-02-16T10:00:00",
      allDay: false,
    },
    {
      title: "Event 2",
      start: "2024-02-17T14:30:00",
      end: "2024-02-17T15:30:00",
      allDay: false,
    },
    {
      title: "Recurring Event",
      start: "T10:00:00",
      end: "T11:00:00",
      allDay: false,
      recurrenceRule: { daysOfWeek: [1] },
    },
  ];

  const handleNewEventModal = () => {
    setShowAddEventModal(true);
  };

  const handleAddEventClose = () => {
    setShowAddEventModal(false);
  };

  return (
    <div className="items-center justify-center">
      <div className="p-6 text-xs sm:px-0">
        <div className="items-center justify-center mx-auto ">
          <CommonButton
            size="extrasmall"
            model="blue"
            text="Schedule "
            onClick={handleNewEventModal}
          />
        </div>
        <FullCalendar
          events={sampleEvents}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          headerToolbar={{
            left: "prev,next,today",
            center: "title",
            right: "",
          }}
          footerToolbar={{
            center: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          nowIndicator={true}
          selectable={true}
        />
      </div>
      <AddEventModal show={showAddEventModal} onClose={handleAddEventClose} />
    </div>
  );
}
 