import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import CommonButton from "../Atoms/Buttons";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const handleNavLinkClick = (sectionId: string) => {
    setNavbarOpen(false);
    window.location.href = `/?section=${sectionId}`;
  };

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-10 bg-opacity-50">
        <div className="container flex flex-wrap items-center justify-between px-1 mx-auto">
          <div className="relative flex justify-between w-full md::w-auto md:static md:block md:justify-start">
            <button
              className="block text-xl leading-none text-white bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer md:hidden focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {" "}
              <GiHamburgerMenu className="" size={36} color="black" />
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "md:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col list-none md:flex-row md:mr-auto">
              <li className="cursor-pointer nav-item">
                <span
                  onClick={() => handleNavLinkClick("about-section")}
                  className="flex items-center py-2 text-lg font-semibold leading-snug md:px-6 text-custom-darkblue hover:opacity-75"
                >
                  <span className="">About</span>
                </span>
              </li>
              <li className="cursor-pointer nav-item">
                <span
                  onClick={() => handleNavLinkClick("resources-section")}
                  className="flex items-center py-2 text-lg font-semibold leading-snug md:px-6 text-custom-darkblue hover:opacity-75"
                >
                  <span className="">Resources</span>
                </span>
              </li>
              <li className="cursor-pointer nav-item">
                <span
                  onClick={() => handleNavLinkClick("store-section")}
                  className="flex items-center py-2 text-lg font-semibold leading-snug md:px-6 text-custom-darkblue hover:opacity-75"
                >
                  <span className="">Our Store</span>
                </span>
              </li>
              <li className="cursor-pointer nav-item">
                <span
                  onClick={() => handleNavLinkClick("contact-section")}
                  className="flex items-center py-2 text-lg font-semibold leading-snug md:px-6 text-custom-darkblue hover:opacity-75"
                >
                  <span className="">Contact</span>
                </span>
              </li>

              <div className="xl:w-[35vw] lg:w-[20vw]"></div>
              <li className="nav-item">
                <Link
                  to="/signin"
                  className="flex items-center text-lg font-semibold leading-snug text-custom-darkblue"
                >
                  <CommonButton
                    size="extrasmall"
                    model="blue"
                    text="Sign in"
                  />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/signup"
                  className="flex items-center text-lg font-semibold leading-snug text-custom-darkblue"
                >
                  <CommonButton
                    size="extrasmall"
                    model="blue"
                    text="Sign up"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
