// Lessons.tsx
import React, { Suspense, lazy, useEffect, useState } from "react";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import AddClassModal from "../../Popups/AddClass";
import { MyLessonsData } from "../../../database/lessondata";
import RefreshModal from "../../Popups/RefreshPopup";

const LazyLoadedLessonsByClass = lazy(() => import("./LessonsByClasses"));

interface ClassData {
  id: string;
  year: number;
  class_name: string;
  content_access_type: string;
}

export default function Lessons() {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [classDataArray, setClassDataArray] = useState<ClassData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [showRefreshModal, setRefreshModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClassData = async () => {
      const token = localStorage.getItem("authenticationToken");
      if (!token) {
        navigate("/login"); // Redirect to login if token is missing
        return;
      }

      try {
        const response = await fetch(API_ENDPOINTS.GET_CLASSES, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          setClassDataArray(result.data);
          setActiveTab(result.data[0]?.id || null); // Set the first class as active
          setError(null); // Clear any previous errors
        } else {
          setError(result.error || "Failed to fetch classes");
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setError("An error occurred while fetching classes");
        handleRefreshModal(); // Show the refresh modal on fetch error
      } finally {
        setLoading(false);
      }
    };

    fetchClassData();
  }, [navigate]);

  const handleTabClick = (classid: string) => {
    setActiveTab(classid);
  };

  const handleNewClassModal = () => {
    setShowAddClassModal(true);
  };

  const handleAddClassClose = () => {
    setShowAddClassModal(false);
  };

  const handleRefreshModal = () => {
    setRefreshModal(true);
  };

  const handleRefreshClose = () => {
    setRefreshModal(false);
    // Optionally, you can retry fetching the class data after refreshing the token
    // fetchClassData();
  };

  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto">
            {/* Tab Navigation */}
            <div className="flex mb-4 justify-left max-w-[100vw] overflow-x-auto">
              {classDataArray.map((classData) => (
                <button
                  key={classData.id}
                  onClick={() => handleTabClick(classData.id)}
                  className={`${
                    activeTab === classData.id
                      ? "bg-blue-200 text-custom-darkblue"
                      : "bg-blue-50 text-custom-darkblue"
                  } md:px-8 px-2 py-1 md:py-4 rounded-b-xl font-semibold`}
                >
                  {classData.year} {classData.class_name}
                </button>
              ))}
              <button
                className="bg-custom-darkblue text-white md:px-8 px-2 py-1 md:py-4 rounded-b-xl font-semibold"
                onClick={handleNewClassModal}
              >
                Add
              </button>
            </div>

            {/* Conditional Rendering for Loading and Error */}
            {loading && <div className="text-center">Loading...</div>}
            {error && <div className="text-center text-red-500">Error: {error}</div>}

            {/* Render lessons component for the selected class */}
            <Suspense fallback={<div>Loading lessons...</div>}>
              {activeTab && !error && (
                <LazyLoadedLessonsByClass classid={activeTab} lessonsData={MyLessonsData} />
              )}
            </Suspense>

            <div>
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      <RefreshModal show={showRefreshModal} onClose={handleRefreshClose} />
      <AddClassModal show={showAddClassModal} onClose={handleAddClassClose} />
    </div>
  );
}
