// src/store/sidebar.ts

export interface SidebarState {
  expanded: boolean;
}

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

const loadState = (): SidebarState => {
  try {
    const serializedState = localStorage.getItem('sidebarState');
    return serializedState ? JSON.parse(serializedState) : { expanded: true };
  } catch (error) {
    return { expanded: true };
  }
};

const saveState = (state: SidebarState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('sidebarState', serializedState);
  } catch (error) {
    // Handle errors while saving state
  }
};

const initialState: SidebarState = loadState();

const sidebarReducer = (state: SidebarState = initialState, action: { type: string }) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      const newState: SidebarState = { ...state, expanded: !state.expanded };
      saveState(newState);
      return newState;
    default:
      return state;
  }
};

export default sidebarReducer;
