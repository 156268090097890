import {
  FaSearch,
  FaBell,
  FaUser,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch} from "react-redux";
import { toggleSidebar } from "../../../store/sidebar";
import { Link } from "react-router-dom";

const Header = () => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(e.target.elements.search.value);
  };

  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };


  return (
    <div className="flex items-center justify-between text-white bg-custom-yellow h-[10vh] min-w-[100vw]">
      <button
        onClick={handleToggleSidebar}
        className="h-[7vh] w-[7vh] bg-custom-darkblue hover:bg-blue-800 flex items-center justify-center ml-2"
      >
          <GiHamburgerMenu  size={28} color="" />
     
      </button>

      <form
        onSubmit={handleSubmit}
        className="flex items-start md:mx-auto md:items-center"
      >
        <div className="relative">
          <input
            type="text"
            name="search"
            placeholder="Search..."
            className="w-[45vw] md:w-[20vw] px-2 ml-5 py-1 pr-8 rounded border text-custom-darkblue"
          />
        </div>
        <button type="submit" className="ml-2 bg-transparent border-none">
          <div className="p-2">
            <FaSearch size={20} />
          </div>
        </button>
      </form>
      <div className="flex items-center lg:mx-10">
        <Link to='/announcement'>
        <FaBell className="m-2 text-2xl cursor-pointer lg:mr-4" />
        </Link>
        <Link to='/userprofile'>
        <FaUser className="m-2 text-2xl cursor-pointer" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
