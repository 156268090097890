import React, { useState } from "react";
import CommonButton from "../Atoms/Buttons";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";

const items = [
  {
    id: 1,
    imageUrl: `${process.env.PUBLIC_URL}/images/Book1.png`,
    link: "/book1",
  },
  {
    id: 2,
    imageUrl: `${process.env.PUBLIC_URL}/images/Book2.png`,
    link: "/book2",
  },
  // Add more items as needed
];

const StoreSlider = () => {
  const [currentItem, setCurrentItem] = useState(0);

  const handlePrev = () => {
    setCurrentItem((prevItem) =>
      prevItem === 0 ? items.length - 1 : prevItem - 1
    );
  };

  const handleNext = () => {
    setCurrentItem((prevItem) =>
      prevItem === items.length - 1 ? 0 : prevItem + 1
    );
  };

  return (
    <div className="relative mx-auto">
      <button
        onClick={handlePrev}
        className="absolute transform -translate-y-1/2 left-[12vw] md:left-[30vw] top-1/2"
      >
        <MdKeyboardDoubleArrowLeft className="text-5xl text-white" />
      </button>
      <button
        onClick={handleNext}
        className="absolute transform -translate-y-1/2 right-[12vw] md:right-[30vw] top-1/2"
      >
        <MdKeyboardDoubleArrowRight className="text-5xl text-white" />
      </button>

      {items.map((item, index) => (
        <div
          key={item.id}
          className={`${
            index === currentItem ? "" : "hidden"
          } md:w-[25vw] md:h-[25vw] w-[50vw] h-[50vw] bg-white mx-auto my-10`}
        >
          <img
            src={item.imageUrl}
            alt={`Book ${item.id}`}
            className="my-auto md:w-[20vw] w-[40vw] h-auto mx-auto"
          />
          <div className="flex items-center justify-center my-6">
            <CommonButton size="large" text="Buy now!" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default StoreSlider;
