import React, { useState } from "react";
import { MdDelete, MdSettings } from "react-icons/md";
import { IoLogoYoutube } from "react-icons/io";
import { HiOutlineDocumentText } from "react-icons/hi";
import EditDocumentModal from "../../Popups/EditDocument";
import EditRecordingModal from "../../Popups/EditRecording";

interface LearningMaterialProps {
  material: LearningMaterialData;
}

interface LearningMaterialData {
  id: number;
  type: "video" | "document";
  name: string;
  url?: string;
  uploadedDateTime: Date;
  subLessonId: number;
  selectedMonths: SelectedMonth[];
}

interface SelectedMonth {
  year: number;
  month: number;
}

const LearningMaterial: React.FC<LearningMaterialProps> = ({ material }) => {
  const [showEditDoc, setShowEditDoc] = useState(false);
  const [showEditRec, setShowEditRec] = useState(false);

  const handleEditDoc = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    console.log("handleEditDoc called with show:", show);
    setShowEditDoc(show);
  };

  const handleEditRec = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    console.log("handleEditRec called with show:", show);
    setShowEditRec(show);
  };

  const handleDeleteRec  = (MaterialId: string) => {
    alert("Do you need to delete video? " + MaterialId);
  };

  const handleDeleteDoc = (MaterialId: string) => {
    alert("Do you need to delete document? " + MaterialId);
  };
  
  

  return (
    <div key={material.id}>
      {material.type === "video" ? (
        <p>
          <div className="flex my-5">
            <div className=" basis-auto">
              <div className="flex ">
                <MdDelete
                  color="darkred"
                  size={25}
                  className="mx-1 cursor-pointer"
                  onClick={() => handleDeleteRec(material.id.toString())}
                />
                <MdSettings
                  color="darkblue"
                  size={25}
                  className="mx-1 cursor-pointer"
                  onClick={() => handleEditRec(true)}
                />
                <IoLogoYoutube color="red" size={25} className="ml-1 " />
              </div>
            </div>
            <div className="ml-3 font-semibold cursor-pointer basis-auto text-custom-darkblue">
              <a href={material.url}>{material.name}</a>
            </div>
          </div>
        </p>
      ) : (
        <div className="flex my-5">
          <div className="flex ">
            <MdDelete
              color="darkred"
              size={25}
              className="mx-1 cursor-pointer"
              onClick={() => handleDeleteDoc(material.id.toString())}
            />
            <MdSettings
              color="darkblue"
              size={25}
              className="mx-1 cursor-pointer"
              onClick={() => handleEditDoc(true)}
            />
            <HiOutlineDocumentText
              color="darkblue"
              size={25}
              className="ml-1 "
            />
          </div>

          <div className="ml-3 font-semibold cursor-pointer basis-auto text-custom-darkblue">
            <p>{material.name}</p>
          </div>
        </div>
      )}

      <EditDocumentModal
        SubLessonId={material.subLessonId}
        show={showEditDoc}
        onClose={() => handleEditDoc(false)}
        id={material.id}
        type={material.type}
        name={material.name}
        uploadedDateTime={material.uploadedDateTime}
        selectedMonths={material.selectedMonths}
      />
      <EditRecordingModal
        SubLessonId={material.subLessonId}
        show={showEditRec}
        onClose={() => handleEditRec(false)}
        id={material.id}
        type={material.type}
        name={material.name}
        uploadedDateTime={material.uploadedDateTime}
        selectedMonths={material.selectedMonths}
        url={material.url}
      />
    </div>
  );
};

export default LearningMaterial;
