import React, { MouseEvent,  useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";

interface AddAnnouncementModalProps {
  show: boolean;
  onClose: () => void;
  selectedClass: string; // Receive selected class ID
}

// interface ClassData {
//   id: string;
//   year: number;
//   class_name: string;
//   content_access_type: string;
// }

const AddAnnuncementModal: React.FC<AddAnnouncementModalProps> = ({
  show,
  onClose,
  selectedClass,
}) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [title, setTitle] = useState<string>(""); // Title for the announcement
  const [description, setDescription] = useState<string>(""); // Description for the announcement
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validation
    if (!selectedClass) {
      alert("No class selected. Please select a class before adding an announcement.");
      return;
    }

    if (!title.trim() || !description.trim()) {
      alert("Please fill in both the title and description.");
      return;
    }

    const requestBody = {
      title: title.trim(),
      description: description.trim(),
    };

    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem("authenticationToken");
      if (!token) {
        setError("Authentication token is missing. Please log in.");
        return;
      }

      const response = await fetch(
        `http://localhost:8092/api/v1/resource-management/announcement/create/${selectedClass}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Announcement created successfully:", result);
        // Optionally, you can display a success message here
        onClose(); // Close the modal after successful submission
      } else {
        setError(result.error || "Failed to create announcement.");
      }
    } catch (err) {
      setError("An error occurred while submitting the announcement.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[80vw] md:h-[50vh] xl:h-[70vh] h-[50vh] shadow-lg rounded-3xl overflow-y-auto">
            <div className="px-10 md:px-20">
              <img
                src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                alt="Logo"
                className="scale-50 mb-4"
              />
            </div>
            <h2 className="text-lg font-semibold text-center text-blue-700 mb-4">
              New Announcement
            </h2>

            {/* Error message */}
            {error && (
              <div className="mb-4 text-red-500 text-center">{error}</div>
            )}

            <form onSubmit={handleSubmit} className="px-4">
              {/* Title Input */}
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-sm font-semibold text-gray-600 mb-1"
                >
                  Announcement Title
                </label>
                <input
                  id="title"
                  value={title}
                  onChange={handleTitleChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  type="text"
                  placeholder="Enter announcement title"
                />
              </div>

              {/* Description Input */}
              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-sm font-semibold text-gray-600 mb-1"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={handleDescriptionChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  rows={4}
                  placeholder="Enter announcement description"
                ></textarea>
              </div>

              {/* Submit Buttons */}
              <div className="flex justify-center space-x-4">
                <button type="submit" disabled={loading}>
                  <CommonButton
                    size="small"
                    model="blue"
                    text={loading ? "Submitting..." : "Set"}
                  />
                </button>
                <button type="button" onClick={onClose} disabled={loading}>
                  <CommonButton size="small" text="Cancel" />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAnnuncementModal;
