import React, { useEffect } from "react";
import NavBar from "../../Ui/Templates/NavBar";
import CommonButton from "../../Ui/Atoms/Buttons";
import { About } from "../../Ui/Organisms/About";
import StoreSlider from "../../Ui/Organisms/StoteSlider";
import { ContactUs } from "../../Ui/Organisms/ContactUs";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import { FaArrowRightLong } from "react-icons/fa6";

function LandingPage() {
  const location = useLocation();

  useEffect(() => {
    const sectionId = new URLSearchParams(location.search).get("section");

    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    }
  }, [location.search]);

  return (
    <div className="flex-col">
      <img
        src={`${process.env.PUBLIC_URL}/images/Ellipse 1.png`}
        alt="Ellipse1"
        className="absolute z-0 w-[50vw] max-w-xs mx-auto md:max-w-full"
      />
      {/* 
      <img
        src={`${process.env.PUBLIC_URL}/images/Ellipse 4.png`}
        alt="Ellipse4"
        className="absolute z-0 mt-[40vh] max-w-xs mx-auto md:max-w-full"
      /> */}
      <div className="w-full ">
        <NavBar />
      </div>

      {/*HOME SECTION*/}
      <div id="home-section" className="w-full h-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:pl-20 basis-1/2">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                alt="Logo"
                className="z-10 w-full max-w-xs mx-auto md:max-w-full"
              />

              <div className="py-5 pl-10 md:py-16">
                <h2 className="text-5xl font-bold text-slate-950 md:text-7xl">
                  With Our Janith
                </h2>
                <h2 className="text-5xl font-bold md:text-7xl text-slate-950">
                  Guidance
                </h2>
                <div>
                  <div className="z-10">
                    {" "}
                    <CommonButton text="Let's learn" icon={FaArrowRightLong} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative bg-cover basis-1/2">
            <img
              src={`${process.env.PUBLIC_URL}/images/student-with-graduation-diploma1-min.png`}
              alt="GraduationPicture"
            />
          </div>
        </div>
      </div>

      {/*ABOUT US SECTION*/}
      <div
        id="about-section"
        className="w-full h-auto bg-center bg-cover bg-custom-darkblue"
      >
        <div className="relative flex flex-col md:flex-row">
          <div className="text-center basis-1/2">
            <img
              src={`${process.env.PUBLIC_URL}/images/TwoStudents.jpg`}
              alt="StudentsPicture"
              className="w-[80vw] xs:w-[40vw] mx-auto block md:p-20 py-5"
            />
          </div>

          <div className="relative bg-cover basis-1/2">
            <div className="">
              <div className="px-10 md:px-0 md:pr-[10vw] md:pt-20">
                <h1 className="text-2xl font-bold text-slate-100 md:text-3xl lg:text-4xl">
                  About us
                </h1>
                <h2 className="text-sm text-slate-100 lg:text-lg">
                  <About />
                </h2>
                <div>
                  <div className="">
                    <CommonButton text="Let's learn" icon={FaArrowRightLong} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*RESOURCES SECTION*/}
      <div
        id="resources-section"
        className="w-full h-auto pt-10 pb-[5vw] bg-slate-100"
      >
        <div className="flex flex-col ">
          <div className=" basis-auto">
            <h1 className="text-3xl font-bold text-center text-custom-darkblue md:text-5xl">
              Resources
            </h1>
            <h6 className="px-3 pt-2 text-base font-normal md:px-10 md:text-lg text-custom-darkblue">
              <About />
            </h6>
          </div>
          <div className="basis-auto">
            <div className="flex flex-col md:flex-row">
              <div className="basis-1/2">
                <div className="flex flex-row">
                  <div className=" basis-1/2">
                    <div className="bg-custom-darkblue h-[45vw] w-[45vw] md:h-[23vw] md:w-[23vw] mx-auto flex flex-col my-[2.5vw]">
                      <div className=" basis-7/12">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/UnlimitedAccess.png`}
                          alt="Logo"
                          className="w-[20vw] md:w-[10vw] sm:w-[15vw] m-auto mt-[5vw] md:mt-[2vw]"
                        />
                      </div>
                      <div className="basis-5/12 ">
                        <h1 className="font-bold text-center text-white text-md lg:text-xl">
                          {" "}
                          Unlimited Access
                        </h1>
                        <h6 className="text-xs text-center text-white lg:mt-2 font-base px-7 lg:text-lg">
                          {" "}
                          Unlimited Access for students teachers
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className=" basis-1/2">
                    <div className="bg-custom-darkblue h-[45vw] w-[45vw] md:h-[23vw] md:w-[23vw] mx-auto flex flex-col my-[2.5vw]">
                      <div className=" basis-7/12">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/ExpertTeachers.png`}
                          alt="Logo"
                          className="w-[20vw] md:w-[10vw] sm:w-[15vw] m-auto mt-[5vw] md:mt-[2vw]"
                        />
                      </div>
                      <div className="basis-5/12 ">
                        <h1 className="font-bold text-center text-white text-md lg:text-xl">
                          {" "}
                          Expert Teachers
                        </h1>
                        <h6 className="text-xs text-center text-white lg:mt-2 font-base px-7 lg:text-lg">
                          {" "}
                          Unlimited Access for students teachers
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="basis-1/2">
                <div className="flex flex-row">
                  <div className=" basis-1/2">
                    <div className="bg-custom-darkblue h-[45vw] w-[45vw] md:h-[23vw] md:w-[23vw] mx-auto flex flex-col my-[2.5vw]">
                      <div className=" basis-7/12">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/LearnAnywhere.png`}
                          alt="Logo"
                          className="w-[20vw] md:w-[10vw] sm:w-[15vw] m-auto mt-[5vw] md:mt-[2vw]"
                        />
                      </div>
                      <div className="basis-5/12 ">
                        <h1 className="font-bold text-center text-white text-md lg:text-xl">
                          {" "}
                          Learn Anywhere
                        </h1>
                        <h6 className="text-xs text-center text-white lg:mt-2 font-base px-7 lg:text-lg">
                          {" "}
                          Unlimited Access for students teachers
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className=" basis-1/2">
                    <div className="bg-custom-darkblue h-[45vw] w-[45vw] md:h-[23vw] md:w-[23vw] mx-auto flex flex-col my-[2.5vw]">
                      <div className=" basis-7/12">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/CloseInteraction.png`}
                          alt="Logo"
                          className="w-[20vw] md:w-[10vw] sm:w-[15vw] m-auto mt-[5vw] md:mt-[2vw]"
                        />
                      </div>
                      <div className="basis-5/12 ">
                        <h1 className="font-bold text-center text-white text-md lg:text-xl">
                          {" "}
                          Close Interaction
                        </h1>
                        <h6 className="text-xs text-center text-white lg:mt-2 font-base px-7 lg:text-lg">
                          {" "}
                          Unlimited Access for students teachers
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*STORE SECTION*/}
      <div
        id="store-section"
        className="w-full h-auto pt-10 pb-[5vw] bg-custom-darkblue"
      >
        <h1 className="text-3xl font-bold text-center text-white md:text-5xl">
          Our Store
        </h1>
        <h6 className="px-3 pt-2 text-base font-normal text-white md:px-10 md:text-lg">
          <About />
        </h6>
        <div className="py-10 mx-auto">
          <StoreSlider />
        </div>
      </div>

      {/*CONTACT US SECTION*/}
      <div id="contact-section" className="w-full h-auto pb-10 bg-white">
        <div className="flex flex-col md:flex-row">
          <div className=" basis-1/2">
            <div>
              <div className="relative">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Ellipse 4.png`}
                  alt="Ellipse4"
                  className="absolute top-0 left-0 w-full h-full"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/woman-working-laptop.png`}
                  alt="woman-working-laptop"
                  className="relative z-10"
                />
              </div>
            </div>
          </div>
          <div className="relative bg-cover basis-1/2">
            <div className="m-10 mt-10">
              <ContactUs />
            </div>
          </div>
        </div>
      </div>

      {/*FOOTER SECTION*/}
      <div>
        <FooterWithSitemap />
      </div>
    </div>
  );
}

export default LandingPage;
