

export  function About() {
    return (
      <div>
        <h5 className="text-justify">
        Lerom is a company that provides a wide range of services, including consulting, marketing, and technology. They are committed to providing their clients with the best possible service and are always looking for ways to improve their offerings.
Lerom's website is a reflection of their commitment to quality and innovation. It is designed to be easy to use and informative, and it provides visitors with a wealth of information about their services. 
      </h5>
      </div>
    )
  }